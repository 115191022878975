import React from 'react'
import InvitePlayer from '../import/InvitePlayer';
//import ScoreBoard from '../import/ScoreBoard';
/*import OverlayOtherPlayerNext from '../import/OverlayOtherPlayerNext';*/
import OverlayCheckMate from '../import/OverlayCheckMate';
import OverlayPromotion from '../import/OverlayPromotion';


export default class Main extends React.Component {

//const BoardContainer = (props) => {

    boardContainerOnClick(disabled) {
        if (disabled) {
            //document.getElementsByClassName("other_player_next")
        }
    }

    render() {
        let nextside = Boolean(Number(this.props.pieces[3]));
        let disabled = nextside === this.props.pieces[1] ? "" : "board-disabled";
    
        let waiting_for_second_player = this.props.pieces[4] === "1" ? "waiting-for-second-player" : "";

        return (
            <div className={"board-container " + disabled + " " + waiting_for_second_player  + " action" + this.props.action}>
                <div className="board-container-main" onClick={()=>this.boardContainerOnClick(disabled)}>
                    {this.props.children}
                    <InvitePlayer id={this.props.pieces[2]}/>
                    {/*<OverlayOtherPlayerNext display={this.props.other_player_next}/>*/}
                    <OverlayCheckMate />
                    <OverlayPromotion game={this.props.pieces}/>
                </div>
                {/*<ScoreBoard />*/}
            </div>
        )
    }
}

//export default BoardContainer