import React from 'react'
import BoardOverlay from './BoardOverlay'

const InvitePlayer = ({id}) => {
    return (<BoardOverlay title="Invite a second player" classes="invite_player"
    content={[
        <div key="1">
            <div>Send this link to another person so they can join the game: </div><div className="waiting-link"><a href={"/join-game/"+id}>{document.location.origin + "/join-game/" + id}</a></div>
        </div>]}/>)
}

export default InvitePlayer
