import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
 
import Main from './components/Main';
import Error from './components/Error';
 
//npm start
//npm install react-router-dom
//npm run build
/*
- replace / with /		-> javascript files in build folder
- replace http://localhost:3000/ with / -> css files in build folder
- replace ./ with /					-> index.html
- replace file db_connection.php	-> build file in /_bak/btdesign/
*/

class App extends Component {
  render() {
    return (
       <BrowserRouter>
        <div>
            <Switch>
				<Route path='/' render={(props) => (<Main {...props} action={0}/> )} exact/>
				<Route path='/play/:game_id' render={(props) => (<Main {...props} action={1} /> )} exact/>
				<Route path='/join-game/:game_id' render={(props) => (<Main {...props} action={2} /> )} exact/>
				<Route path="*" component={Error}/>
				<Route component={Error}/>
            </Switch>
        </div> 
      </BrowserRouter>
    );
  }
}
 
export default App;