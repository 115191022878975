import React from 'react';
import { pieceHandleMouseDown, pieceHandleMouseUp } from '../js/piece_click_and_drag';

const JoinGame = ({pieces, pieces_names, action}) => {

    let new_pieces_list = pieces[0].map((it) => {

        if (it !== null) {
            return [it[0], it[1], [7-it[2][0], 7-it[2][1]]]
        } 
        else {
            return null
        }

    });

    return pieces[0].map((items, index) => {

        if (items !== null) {

            let color = items[1] === true ? "black" : "white";

            let image_path = "/img/"+items[0]+"_"+color+".svg"; 

            let current_piece = pieces_names[items[0]]

            let image_alt = (color.charAt(0).toUpperCase() + color.slice(1)) + " " + (current_piece.charAt(0).toUpperCase() + current_piece.slice(1));

            let t, l;
            l = 7 - items[2][0];
            t = 7 - items[2][1];

            let left = l * 70;
            let top = t * 70;

            let complete_pieces = [new_pieces_list, !pieces[1], pieces[2], pieces[3]];

            return (
                <div className="piece" id={"piece_"+index} style={{left: left, top: top}} 
                onMouseUp={()=>pieceHandleMouseUp(items[2], pieces, index, action, complete_pieces)} 
                onMouseDown={(e) => pieceHandleMouseDown([items[0], items[1], [l, t]], complete_pieces, index, action, e, pieces)} key={index}>
                    <img src={image_path} alt={image_alt} onDragStart={(e) => {e.preventDefault()}}/>
                </div>
            )

        }
        else {
            return ""
        }
    })
}

export default JoinGame
