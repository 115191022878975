import axios from 'axios';
import {arrayEqual} from './calculate_possible_moves';
import { setPawnData } from '../import/OverlayPromotion';

export let clickable = true;

export const moveToSquare = (pos, index, game, action) => {

    clickable = false; //Lock click function temporarily to avoid double clicking

    let elem = document.getElementById("piece_"+index);

    game[0][index][2] = pos; //change the game array to move the piece

    var params = {
        pos: pos,
        count: index,
        game: game,
        action: action
    }

    
    axios.post('/api/move_piece.php', params)

    for (let s = 0; s < 64; s++) {
        document.getElementsByClassName('square')[s].classList.remove("possible_field", "selected_field", "capture_piece");
    }
    

    //Set position directly to avoid delay with reloadBoard
    if (action === 1) {
        elem.style.left = (pos[0]*70)+"px";
        elem.style.top = (pos[1]*70)+"px";
    }
    else if (action === 2) {
        elem.style.left = ((7 - pos[0])*70)+"px";
        elem.style.top = ((7 - pos[1])*70)+"px";
    }

    setTimeout(() => {
        clickable = true;
    }, 1500);

    if (game[0][index][0] === 0 && (pos[1] === 0 || pos[1] === 7)) {
        document.getElementsByClassName('overlay_promotion')[0].classList.add('shown');
        setPawnData(pos, index);
    }

    if (!game[0][index][3]) {
        game[0][index][3] = true; //if own king/rook not moved yet -> change to moved -> for castling
    }

}
export const capturePiece = (pos, index, game, action) => {

    for (let i = 0; i < game[0].length; i++) {

        if (game[0][i] !== null) {

            if ( arrayEqual(game[0][i][2], pos) ) {

                if (game[0][i][0] === 5) {
                    return false;
                }


                document.getElementById("piece_"+i).style.display = "none";

                game[0][i] = null;
                
                break;
    
            }
        }
    }

    moveToSquare(pos, index, game, action);

}