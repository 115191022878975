import React from 'react'

const BoardOverlay = ({title, content, classes}) => {
    return (
        <div className={"waiting " + classes}>
            <div className="waiting-background"></div>
            <div className="waiting-message">
                <h2>{title}</h2>
                <div>{content}</div>
            </div>
        </div>
    )
}

export default BoardOverlay
