import React from 'react'

const MainMenu = (props) => {
    return (
        <div className="main-menu">
            {/*<div><h1>Main Menu</h1></div>*/}
            <div className="main-menu-button">{props.children}</div>
        </div>
    )
}

export default MainMenu
