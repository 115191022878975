import React from 'react'

const Button = (props) => {
    return (
        <div className="button" onClick={props.onclick}>
            {props.children}
        </div>
    )
}

export default Button
