import React from 'react'
import BoardOverlay from './BoardOverlay'
import axios from 'axios'

//let position = [];
let index;

const OverlayPromotion = ({game}) => {
    /*console.log(game)
    console.log(position)*/
    return (<BoardOverlay title="Your pawn has reached the other side of the board" classes="overlay_promotion"
    content={[
        <div key="2">
            Choose a piece for promotion
            <div className="piece-selection">
                <div className={"piece-selection-queen-"+game[1]} onClick={() => promotion(4, game)}></div>
                <div className={"piece-selection-rook-"+game[1]} onClick={() => promotion(1, game)}></div>
                <div className={"piece-selection-bishop-"+game[1]} onClick={() => promotion(3, game)}></div>
                <div className={"piece-selection-knight-"+game[1]} onClick={() => promotion(2, game)}></div>
            </div>
        </div>]}/>)
}

export function setPawnData(pos, ind) {
    //position = pos;
    index = ind;
}

function promotion(id, game) {
    //console.log(id);

    let new_game = game;

    new_game[0][index][0] = id;

    var params = {
        /*position: position,
        id: id,
        game: game,
        index: index*/
        game: new_game
    }

    axios.post('/api/promotion.php', params)/*.then(function(response) {
        console.log(response)
    });*/

    document.getElementsByClassName('overlay_promotion')[0].classList.remove('shown');

}

export default OverlayPromotion
