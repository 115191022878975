import {pieceClickOrMouseDown, movePiece, checkmate_disabled, checkIfPossibleField, arrayEqual} from  '../js/calculate_possible_moves.js';
import { clickable } from '../js/move_piece.js';

//check which piece was clicked to avoid double movePiece
let clicked = -1;
let dragging = false;

export function pieceHandleMouseDown(item, pieces, index, action, e_mousedown, original_pieces) {

    if (clickable && !checkmate_disabled) {
    
        //Check if next side is own side
        let nextside = Boolean(Number(pieces[3]));

        if (nextside === pieces[1]) {
            pieceClickOrMouseDown(item, pieces, index, action, original_pieces);

            if (item[1] === pieces[1]) {
                prepareDragElement(index, e_mousedown);
            }
        }

        clicked = index;

    }

}
export function pieceHandleMouseUp(square_id, game_id, index, action, rotated_pieces) {
    // only execute if piece was clicked and own side is next

    //console.log(game_id)

    if (clicked !== index && clickable && !checkmate_disabled) {
        movePiece(square_id, false, game_id, 1);
        clicked = -1;
    }

    endDrag(index, square_id, game_id, action, rotated_pieces);

}


let e_mousedown, elem_mouse_relative, index, elem, rect;

function prepareDragElement(index_, e_mousedown_) {

    e_mousedown = e_mousedown_;
    index = index_;

    //console.log(e_mousedown)

    let rect_mousedown = e_mousedown.currentTarget.getBoundingClientRect()
    elem_mouse_relative = [e_mousedown.pageY - rect_mousedown.top, e_mousedown.pageX - rect_mousedown.left]
    elem = document.getElementById("piece_"+index);
    elem.classList.add("no-animation");
    window.addEventListener("mousemove", mousemove, false)

    dragging = true;

}

function mousemove(e) {
    pieceDrag(e, e_mousedown, elem_mouse_relative, index, elem)
}

function pieceDrag(e) {

    let parent = elem.parentNode;

    if (parent !== null) {

        rect = parent.getBoundingClientRect();

        elem.style.top = ((e.pageY - rect.top) - elem_mouse_relative[0]) + "px";
        elem.style.left = ((e.pageX - rect.left) - elem_mouse_relative[1]) + "px";

    }
}

function endDrag(index__, square_id, game_id, action, rotated_pieces) {

    if (dragging) {

        let elem = document.getElementById("piece_"+index);
        elem.classList.remove("no-animation");
        window.removeEventListener("mousemove", mousemove)

        if (rect !== undefined) {
            
            let child_rect = elem.getBoundingClientRect();

            let field_y = parseInt((child_rect.top - rect.top + elem_mouse_relative[0]) / 70);
            let field_x = parseInt((child_rect.left - rect.left + elem_mouse_relative[1]) / 70);

            let check_field = checkIfPossibleField(index, field_x, field_y);

            if ((field_y >= 0 && field_x >= 0) && check_field !== 2) { //is possible field -> move there

                //console.log("I")

                if (check_field === 0) {
                    movePiece([field_x, field_y], false, game_id, action);
                    clicked = -1;
                }
                else {
                    //console.log("find out item to capture")
                    //console.log(game_id[0][index__])

                    let coords_to_compare = action === 1 ? [field_x, field_y] : [7-field_x, 7-field_y]

                    for (let p = 0; p < game_id[0].length; p++) {

                        if (game_id[0][p] !== null) {

                            /*console.log(game_id[0][p])
                            console.log([field_x, field_y])*/
        
                            if ( arrayEqual(game_id[0][p][2], coords_to_compare) ) { //Piece player wants to capture

                                if (action === 1) {
                                    pieceClickOrMouseDown(game_id[0][p], game_id, index, action);
                                }
                                else {
                                    pieceClickOrMouseDown(rotated_pieces[0][p], rotated_pieces, index, action, game_id);
                                }

                                break;
                            }

                        }
                    }
                }
            }
            else { //not a possible field, return to original field

                //console.log("II")
                let original_field = action === 1 ? game_id[0][index][2] : [7-game_id[0][index][2][0], 7-game_id[0][index][2][1]]
                elem.style.top = ( original_field[1] * 70 ) + "px";
                elem.style.left = ( original_field[0] * 70 ) + "px";
            }
            /*elem.style.top = ( field_y * 70 ) + "px";
            elem.style.left = ( field_x * 70 ) + "px";*/
        }
    }
}