import React from 'react'
//import BoardOverlay from './BoardOverlay'
import { wronglyMarkedCheckmate } from '../js/calculate_possible_moves'

const OverlayCheckMate = ({id}) => {

    return (<div className="checkmate_overlay"><div className="checkmate_overlay_content">

            <div className="title"><span id="checkmate_side"></span></div>

            <div className="message_buttons" key="w3">
                <div className="message_button"><span onClick={closeMessage}>Close this message</span></div>
                <div className="message_button"><span onClick={wronglyMarkedCheckmate}>Wrongly marked as checkmate?</span></div>
            </div>

        </div></div>)
}

function closeMessage() {
    document.getElementsByClassName('checkmate_overlay')[0].classList.remove('shown')
}

export default OverlayCheckMate
