import React from 'react'

const Error = () => {
    return (
        <div>
            <b>Error: </b> Page not found
        </div>
    )
}

export default Error
