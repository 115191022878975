import {moveToSquare, capturePiece} from  '../js/move_piece';

let piece_selected = false;
let pieces, poss, all_possible_moves = [];
let side, active_piece;

export const pieceClickOrMouseDown = (item, pieces_param, index, action, original_pieces) => {

    pieces = pieces_param[0];
    side = pieces_param[1];

    let pieces_to_use;

    if (original_pieces !== undefined) {
        pieces_to_use = original_pieces;
    }
    else {
        pieces_to_use = pieces_param;
    }

    //console.log(1)

        if (!piece_selected || item[1] === side) {

            /*console.log(item[1])
            console.log(side)
            console.log(item[1] === side)*/

            if (item[1] === side) {

                poss = all_possible_moves[index];

                for (let s = 0; s < 64; s++) {
                    document.getElementsByClassName('square')[s].classList.remove("possible_field", "selected_field", "capture_piece");
                }
                poss[0].forEach(p0 => {
                    if (item[0] === 5) {
                        if ( checkIfPieceCanBeCaptured("b", action, p0, undefined, true)[0] !== true ) {
                            document.getElementById(p0[0]+"_"+p0[1]).classList.add("possible_field"); 
                        }
                        /*else {
                            console.log(p0)
                            console.log( checkIfPieceCanBeCaptured("b", action, p0) )
                            console.log( pieces[21] )
                        }*/
                    }
                    else {
                        document.getElementById(p0[0]+"_"+p0[1]).classList.add("possible_field"); 
                    }

                });
                poss[1].forEach(p1 => {
                    if (item[0] === 5) {
                        if ( checkIfPieceCanBeCaptured("b", action, p1, undefined, true)[0] !== true ) {
                            document.getElementById(p1[0]+"_"+p1[1]).classList.add("capture_piece");
                        }
                    }
                    else {
                        document.getElementById(p1[0]+"_"+p1[1]).classList.add("capture_piece");
                    }
                });
                document.getElementById(item[2][0]+"_"+item[2][1]).classList.add("selected_field");
        
                piece_selected = true;
                active_piece = index;

            }
            
        }
        else {

            //console.log("d")

            let pos;
            let is_rotated;
            if (action === 1) { //Normal Board
                //console.log("a")
                pos = item[2];
                is_rotated = false;
            }
            else { //Rotated Board
                //console.log("b")
                pos = [7-item[2][0],7-item[2][1]];
                is_rotated = true;
            }

            /*console.log(pos)
            console.log(poss[1])
            console.log(item[2])*/

            poss[1].forEach((p1) => {
                if (arrayEqual(p1, item[2])) {

                    //console.log( p1 )
                    
                    if (capturePiece(pos, active_piece, pieces_to_use, action, is_rotated) !== false) { //If function does not return false (false only if other king)
                        piece_selected = false;
                    }
                }
            })
        }
}


export function getPossibleMoves(item) {

    if (item !== null) {

        let result = [[], [], []];
        switch (item[0]) {
            case 0:
                //echo "pawn";
    
                let go_forward = 1; //Normally, pawn can go forward 1 square

                let limits_st, limits_pa;
                if (item[1] === side) {
                    if (item[2][1] === 6) {
                        go_forward = 2; //If not moved yet, can move 2 squares
                    }

                    limits_st = [go_forward,0,0,0];
                    limits_pa = [[1,-1], [-1,-1]];
                }
                else {
                    if (item[2][1] === 1) {
                        go_forward = 2; //If not moved yet, can move 2 squares
                    }
                    
                    limits_st = [0,go_forward,0,0];
                    limits_pa = [[1,1], [-1,1]];
                }
                
    
                let stra_p = goStraight(limits_st, item[2], false);
                let pawn_p = goPawn(item[2], limits_pa);
    
                result[0] = stra_p[0].concat(pawn_p[0]);
                result[1] = stra_p[1].concat(pawn_p[1]);
                break;
            case 1:
                //echo "rook";
                result = goStraight([8,8,8,8], item[2], true);
                break;
            case 2:
                //echo "knight";
                result = goKnight(item[2]);
                break;
            case 3:
                //echo "bishop";
                result = goDiagonal([8,8,8,8], item[2]);
                break;
            case 4:
                //echo "queen";
                let stra_q = goStraight([8,8,8,8], item[2], true);
                let diag_q = goDiagonal([8,8,8,8], item[2]);
                
                result[0] = stra_q[0].concat(diag_q[0]); //free squares
                result[1] = stra_q[1].concat(diag_q[1]); //sqares with piece of other side on it
                result[2] = stra_q[2].concat(diag_q[2]); //sqares with piece of other side on it
                result[3] = stra_q[3].concat(diag_q[3]); //sqares with piece of other side on it
                break;
            case 5:
                //echo "king";
                let stra_k = goStraight([1,1,1,1], item[2], true);
                let diag_k = goDiagonal([1,1,1,1], item[2]);
                
                result[0] = stra_k[0].concat(diag_k[0]); //free squares
                result[1] = stra_k[1].concat(diag_k[1]); //sqares with piece of other side on it
                result[2] = stra_k[2].concat(diag_k[2]); //for checkmate
                break;
            default:
        }
        return result;

    }

}


function goStraight(limits, pos, can_hit) {

    let free_fields = [[],[],[],[]];

    let poss_stra = [[0,-1], [0,1], [-1,0], [1,0]];

    for (let i = 0; i < poss_stra.length; i++) {

        let steps = 0;
        let x_coordinate = pos[0];
        let y_coordinate = pos[1];
        while (true) {

            steps++;
            x_coordinate += poss_stra[i][0];
            y_coordinate += poss_stra[i][1];

            if (x_coordinate <= 7 && x_coordinate >= 0 && y_coordinate <= 7 && y_coordinate >= 0 && steps <= limits[i]) {

                let way_blocked = isWayBlocked(x_coordinate, y_coordinate);

                if (way_blocked === 1 || (way_blocked === 2 && !can_hit)) { //piece of own side
                    free_fields[2].push( [ x_coordinate, y_coordinate ] );
                    break;
                }
                else if (way_blocked === 2 && can_hit) { //piece from other side
                    free_fields[1].push( [ x_coordinate, y_coordinate ] );
                    break;
                }
                else if (way_blocked === 3) {

                    let x_ = x_coordinate+poss_stra[i][0];
                    let y_ = y_coordinate+poss_stra[i][1];

                    if (x_coordinate >= 0 && y_coordinate >= 0 && x_coordinate < 8 && y_coordinate < 8) {
                        free_fields[3] = [ x_coordinate,y_coordinate ];
                    }

                    if (x_ >= 0 && y_ >= 0 && x_ < 8 && y_ < 8) {
                        free_fields[3] = [ x_,y_ ];
                    }

                    break;
                }
                else { //free square
                    free_fields[0].push( [ x_coordinate, y_coordinate ] );
                }
                
            }
            else {
                break;
            }
        }

    }
    return free_fields;

}

function goDiagonal(limits, pos) {

    let free_fields = [[],[],[]];

    let poss_diag = [[1,1], [-1,1], [1,-1], [-1,-1]];

    for (let i = 0; i < poss_diag.length; i++) {

        let steps = 0;
        let x_coordinate = pos[0];
        let y_coordinate = pos[1];
        while (true) {

            steps++;
            x_coordinate += poss_diag[i][0];
            y_coordinate += poss_diag[i][1];
            
            if (x_coordinate <= 7 && x_coordinate >= 0 && y_coordinate <= 7 && y_coordinate >= 0 && steps <= limits[i]) {

                let way_blocked = isWayBlocked(x_coordinate, y_coordinate);

                if (way_blocked === 1) {
                    free_fields[2].push( [ x_coordinate, y_coordinate ] );
                    break;
                }
                else if (way_blocked === 2) {
                    free_fields[1].push( [ x_coordinate, y_coordinate ] );
                    break;
                }
                else if (way_blocked === 3) {

                    let x_ = x_coordinate+poss_diag[i][0];
                    let y_ = y_coordinate+poss_diag[i][1];

                    if (x_coordinate >= 0 && y_coordinate >= 0 && x_coordinate < 8 && y_coordinate < 8) {
                        free_fields[3] = [ x_coordinate,y_coordinate ];
                    }

                    if (x_ >= 0 && y_ >= 0 && x_ < 8 && y_ < 8) {
                        free_fields[3] = [ x_,y_ ];
                    }

                    break;
                }
                else {
                    free_fields[0].push( [ x_coordinate, y_coordinate ] );
                }
                
            }
            else {
                break;
            }
        }
    }

    return free_fields;
}

function goKnight(pos) {

    let free_fields = [[],[],[]];

    let poss_knight = [[-2, 1], [-2, -1], [2, 1], [2, -1], [-1, 2], [-1, -2], [1, 2], [1, -2]];
    
    for (let i = 0; i < poss_knight.length; i++) {
        let x_coordinate = pos[0] + poss_knight[i][0];
        let y_coordinate = pos[1] + poss_knight[i][1];

        if (x_coordinate <= 7 && x_coordinate >= 0 && y_coordinate <= 7 && y_coordinate >= 0) {

            let way_blocked = isWayBlocked(x_coordinate, y_coordinate);

            if (way_blocked === 1) {
                free_fields[2].push( [ x_coordinate, y_coordinate ] );
                continue;
            }
            else if (way_blocked === 2) {
                free_fields[1].push( [ x_coordinate, y_coordinate ] );
                continue;
            }
            else if (way_blocked !== 3) {
                free_fields[0].push( [ x_coordinate, y_coordinate ] );
            }
            
        }
    }

    return free_fields;
}

function goPawn(pos, poss_diag) {

    let free_fields = [[],[]];
    //let poss_diag = [[1,-1], [-1,-1]];

    for (let i = 0; i < poss_diag.length; i++) {

        let steps = 0;
        let x_coordinate = pos[0];
        let y_coordinate = pos[1];
        while (true) {

            steps++;
            x_coordinate += poss_diag[i][0];
            y_coordinate += poss_diag[i][1];
            
            if (x_coordinate <= 7 && x_coordinate >= 0 && y_coordinate <= 7 && y_coordinate >= 0 && steps <= 1) {

                let way_blocked = isWayBlocked(x_coordinate, y_coordinate);

                if (way_blocked === 1) {
                    break;
                }
                else if (way_blocked === 2) {
                    free_fields[1].push( [ x_coordinate, y_coordinate ] );
                    break;
                }
                
            }
            else {
                break;
            }
        }
    }
    return free_fields;
}

function goCastling(action) {

    let free_fields = [];
    let item;

    if (side) {
        item = action === 1 ? pieces[28] : pieces[3]; //get king from own side
    }
    else {
        item = action === 1 ? pieces[27] : pieces[4]; //get king from own side
    }

    let can_add = [true, true]

    if (!item[3]) { //if king has not been moved yet

        let cipcbc_a = checkIfPieceCanBeCaptured("b", action, [2,7])[0];
        let cipcbc_b = checkIfPieceCanBeCaptured("b", action, [4,7])[0];
        let cipcbc_c = checkIfPieceCanBeCaptured("b", action, [3,7])[0];
        let cipcbc_d = checkIfPieceCanBeCaptured("b", action, [5,7])[0];

        let rook_ids_gocastling = action === 1 ? [24,31] : [7,0];

        for (let p = 0; p < pieces.length; p++) {

            if (pieces[p] !== null) {

                if (pieces[rook_ids_gocastling[0]] !== null && !pieces[rook_ids_gocastling[0]][3]) { //calculate for left rook

                    if (side) {
                        if ( arrayEqual([1,7], pieces[p][2]) || arrayEqual([2,7], pieces[p][2]) || cipcbc_a ) {
                            can_add[0] = false;
                        }
                    }
                    else {
                        if ( ((arrayEqual([1,7], pieces[p][2]) || arrayEqual([2,7], pieces[p][2]) || arrayEqual([3,7], pieces[p][2])) && pieces[p][0] !== 5) || cipcbc_c ) {
                            can_add[0] = false;
                        }
                    }

                }
                else {
                    can_add[0] = false;
                }


                if (pieces[rook_ids_gocastling[1]] !== null && !pieces[rook_ids_gocastling[1]][3]) { //calculate for right rook

                    if (side) {
                        if ( arrayEqual([4,7], pieces[p][2]) || arrayEqual([5,7], pieces[p][2]) || arrayEqual([6,7], pieces[p][2]) || cipcbc_b ) {
                            can_add[1] = false;
                        }
                    }
                    else {
                        if ( arrayEqual([5,7], pieces[p][2]) || arrayEqual([6,7], pieces[p][2]) || cipcbc_d ) {
                            can_add[1] = false;
                        }
                    }
                }
                else {
                    can_add[1] = false;
                }

            }

            if (!can_add[0] && !can_add[1]) { //if both ways blocked, break
                break;
            }
        }

        let rook_ids;
        let rook_pos;

        if (action === 1) {
            rook_ids = [24,31];
            rook_pos = [[3,7],[5,7],[2,7],[4,7]];
        }
        else {
            rook_ids = [7,0]
            rook_pos = [[4,0],[2,0],[5,0],[3,0]];
        }
 

        if (side) { //side black
            if (can_add[0]) {
                free_fields.push([1,7,rook_ids[0],rook_pos[2]])
            }
            if (can_add[1]) {
                free_fields.push([5,7,rook_ids[1],rook_pos[3]])
            }
        }
        else { //side white
            if (can_add[0]) {
                free_fields.push([2,7,rook_ids[0],rook_pos[0]])
            }
            if (can_add[1]) {
                free_fields.push([6,7,rook_ids[1],rook_pos[1]])
            }
        }
    }
    return free_fields;
}

function isWayBlocked(x_coordinate, y_coordinate) {

    for (let p = 0; p < pieces.length; p++) {

        if (pieces[p] !== null) {
            if (x_coordinate === pieces[p][2][0] && y_coordinate === pieces[p][2][1]) {

                if (pieces[p][0] === 5) { //if standing on field is king -> for checkforcheckmate
                    return 3;
                }

                if (pieces[p][1] === side) { //piece -> same side
                    return 1;
                }
                else { //other side
                    return 2;
                }
            }
        }

    }
    return 0;

}


export const movePiece = (square_id, board_clicked, game, action) => {


    if (piece_selected) {

        let pos;
        if (action === 1) {
            pos = square_id;
        }
        else {
            pos = [7-square_id[0],7-square_id[1]];
        }

        for (let p1 = 0; p1 < poss[0].length; p1++) {

            if (arrayEqual([poss[0][p1][0], poss[0][p1][1]], square_id)) {

                //console.log(3)
                
                if (poss[0][p1][2] !== undefined) { //for castling

                    game[0][poss[0][p1][2]][2] = [ poss[0][p1][3][0],poss[0][p1][3][1] ];

                    let el = document.getElementById("piece_"+(poss[0][p1][2]));
                    if (action === 1) {
                        el.style.left = (poss[0][p1][3][0]*70)+"px";
                        el.style.top = (poss[0][p1][3][1]*70)+"px";
                    }
                    else if (action === 2) {
                        el.style.left = ((7 - poss[0][p1][3][0])*70)+"px";
                        el.style.top = ((7 - poss[0][p1][3][1])*70)+"px";
                    }

                }
                
                //console.log( game[0][active_piece][0] )
                if (game[0][active_piece][0] === 5) { //for king: check if check at new position

                    /*console.log( checkIfPieceCanBeCaptured("b", action, square_id) )
                    console.log( pieces[checkIfPieceCanBeCaptured("b", action, square_id)[1]] )*/
                    if ( checkIfPieceCanBeCaptured("b", action, square_id)[0] !== true ) {
                        moveToSquare(pos, active_piece, game, action);
                    }                        
                }
                else {
                    if (board_clicked) { //if clicked on a possible field
                        piece_selected = false;
                    }
                    moveToSquare(pos, active_piece, game, action);
                }
                break;

            }
        }

    }
    

}

export function arrayEqual(s1, s2) {
    if (JSON.stringify( s1 ) === JSON.stringify( s2 )) {
        return true;
    }
    return false;
}

function arrayContains(s1, s2) {
    for (let q = 0; q < s1.length; q++) {

        if (JSON.stringify( s1[q] ) === JSON.stringify( s2 )) {
            return true;
        }
    }

    return false;
}

export function getAllPossibleMoves(pieces_list, action) {

    pieces = pieces_list[0];
    side = pieces_list[1];

    all_possible_moves = [];
    
    for (let c = 0; c < pieces.length; c++) {

        //Because number of possible fields is lower than number of blocked fields, checkforcheckmate doesn't work
        //Maybe not remove possible field if check, instead for example replace with null
        /*if (pieces[c] !== null && pieces[c][0] === 5) {
            all_possible_moves.push( getPossibleMoves(pieces[c]).map(m => {
                
                return m.map(n => { //Remove all squares where king can be captured
                    if (checkIfPieceCanBeCaptured("b", action, n)[0]) {
                        return [...n, false];
                    }
                    else {
                        return [...n, true];
                    }
                } )
            }) );
        }
        else {*/
            all_possible_moves.push(getPossibleMoves(pieces[c]));
        //}

    }


    //FOR CASTLING
    let castling = goCastling(action);
    let idtoadd;

    if (side) {
        idtoadd = action === 1 ? 28 : 3;
    }
    else {
        idtoadd = action === 1 ? 27 : 4;
    }

    if (castling !== false) {
        all_possible_moves[idtoadd][0].push(...castling)
    }
    //END FOR CASTLING

    //console.log(all_possible_moves)

    return all_possible_moves;
}

export function checkForCheckmate(pieces, action) {

    let kftc_k_other = [] 
    let kftc_k_own = [];

    let kingids;
    if (side) {
        kingids = action === 1 ? [4, 28] : [27, 3];
    }
    else {
        kingids = action === 1 ? [3, 27] : [28, 4];
    }

    if (all_possible_moves[kingids[0]] !== undefined && all_possible_moves[kingids[1]] !== undefined) {

        if (action === 1) {
            kftc_k_other = all_possible_moves[kingids[0]][0].concat(all_possible_moves[kingids[0]][2])
            kftc_k_own = all_possible_moves[kingids[1]][0].concat(all_possible_moves[kingids[1]][1])
        }
        else if (action === 2) {
            kftc_k_other = all_possible_moves[kingids[0]][0].concat(all_possible_moves[kingids[0]][2]) 
            kftc_k_own = all_possible_moves[kingids[1]][0].concat(all_possible_moves[kingids[1]][1])
        }

    }

    let blocked_fields_own = [];
    let blocked_fields_other = [];
    let involved_pieces = [];
    let kkotc = kftc_k_other.length;
    let kkowc = kftc_k_own.length;

    /*console.log(kftc_k_other)
    console.log(kkotc)
    console.log(kftc_k_own)
    console.log(kkowc)*/

    /*console.log(pieces[kingids[1]][2])
    console.log(pieces[kingids[0]][2])

    console.log("--------------")*/

    //check if king is under attack
    let own_king_attacked = false;
    let other_king_attacked = false;

    all_possible_moves.forEach((a, i) => {

        if (a !== undefined) {

            //check if king is under attack
            if (a[3] !== undefined && a[3].length > 0) {
                own_king_attacked = arrayEqual(a[3], pieces[kingids[1]][2])
                other_king_attacked = arrayEqual(a[3], pieces[kingids[0]][2])
            }

            let a_1 = a[1] === undefined ? [] : a[1]
            let a_2 = a[2] === undefined ? [] : a[2]

            let r = a[0].concat(a_1).concat(a_2);

                    r.forEach(x => { //All Moves for Single Piece

                        if ( (action === 1 && i >= 16) || (action === 2 && i < 16) ) {
    
                            kftc_k_other.forEach(k_ => {

                                if (a[3] !== undefined && arrayEqual( [a[3][0], a[3][1]],k_ ) && !arrayContains(blocked_fields_other, k_) ) {
                                    blocked_fields_other.push(k_)
                                }

                                if ( arrayEqual(x, k_) && !arrayContains(blocked_fields_other, x) ){
                                    if ( !arrayContains(involved_pieces, i) ) {
                                        involved_pieces.push(i);
                                    }
    
                                    blocked_fields_other.push(x)
                                }

                                /*console.log(blocked_fields_other)
                                console.log(kkotc)*/

                                if ( ((action === 1 && i >= 16) && blocked_fields_other.length === kkotc) || ((action === 2 && i < 16) && blocked_fields_other.length === kkotc) ) { //Two Pieces can reach same square

                                    //console.log( involvedPiecesLogic(involved_pieces, action, blocked_fields_other, "b") )

                                    if (involvedPiecesLogic(involved_pieces, action, blocked_fields_other, "b")) {

                                        /*console.log( checkIfPieceCanBeCaptured("b", action, pieces[kingids[1]][2], side) )
                                        console.log( checkIfPieceCanBeCaptured("b", action, pieces[kingids[0]][2], side) )*/
                                        
                                        if (other_king_attacked) {
                                            displayCheckmate( (pieces[i][1] === true ? "White" : "Black") + " is checkmated" );
                                            //console.log("CHECKMATE OWN")
                                        }
                                        else {
                                            //console.log( noOtherPieceToDraw(action, "b") )
                                            if (noOtherPieceToDraw(action, "b")) {
                                                displayCheckmate( "Stalemate" );
                                            }
                                            //console.log("STALEMATE OWN")
                                        }
                                        
                                    }

                                }
                            })
                        }
                        else if ( (action === 1 && i < 16) || (action === 2 && i >= 16) ) {

                            kftc_k_own.forEach(k_ => {

                                let k = [k_[0], k_[1]]

                                if ( arrayEqual( a[3],k ) && !arrayContains(blocked_fields_own, k) ) {
                                    blocked_fields_own.push(k)
                                }
    
                                if ( arrayEqual(x, k) && !arrayContains(blocked_fields_own, x) ){  
                                    if ( !arrayContains(involved_pieces, i) ) {
                                        involved_pieces.push(i);
                                    }
    
                                    blocked_fields_own.push(x);
    
                                }

                                /*console.log(blocked_fields_own)
                                console.log(kkowc)*/

                                if ( ((action === 1 && i < 16) && blocked_fields_own.length === kkowc) || ((action === 2 && i >= 16) && blocked_fields_own.length === kkowc) ) {

                                    //console.log( involvedPiecesLogic(involved_pieces, action, blocked_fields_other, "a") )
                                    
                                    if (involvedPiecesLogic(involved_pieces, action, blocked_fields_own, "a")) {   

                                        if (own_king_attacked) {
                                            displayCheckmate( "You are checkmated" );
                                            //console.log("CHECKMATE OTHER")
                                        }
                                        else {
                                            if (noOtherPieceToDraw(action, "a")) {
                                                displayCheckmate( "Stalemate" );
                                            }
                                            //console.log("STALEMATE OTHER")
                                        }
                                    }

                                }
                            })
                        }
                        
                    })

        }

    })
}
export let checkmate_disabled = false;

function involvedPiecesLogic(involved_pieces, action, blocked_fields, field_can_be_captured_param) {
    /*
    LOGIC FOR SINGLE PIECE CHECKMATING 
    */
    let isWayOfSinglePieceBlocked = false; //is the Way Of a Single Piece checkmating the king Blocked

    if (involved_pieces.length === 1) {

        let blocked_field_free = checkIfPieceCanBeCaptured(field_can_be_captured_param, action, blocked_fields[0], side); //Check field next to king first to get information if it is blocked and which piece is blocking it

        

        if ( blocked_field_free[0] === null ) { //No piece except the king of the targeted side can block the field next to the king

            let squares_between = getSquaresBetween(blocked_fields[0], pieces[involved_pieces[0]][2]);

            for (let s = 0; s < squares_between.length; s++) {

                if (checkIfPieceCanBeCaptured(field_can_be_captured_param, action, squares_between[s], side)[0] !== null) {
                    isWayOfSinglePieceBlocked = true;

                }

            }

        }
        else {
            isWayOfSinglePieceBlocked = true;
        }

    }
    
    if ( involved_pieces.length > 1 || (involved_pieces.length === 1 && isWayOfSinglePieceBlocked === false) ) {
        return true;
    }
    return false;
}

function checkIfPieceCanBeCaptured(param, action, position, exclude_king, pawn_spacial_capture) {

    for (let a = 0; a < all_possible_moves.length; a++) {

        if (all_possible_moves[a] !== undefined) {

            /* FOR PAWN DIAGONAL CAPTURE */
            let p_b = all_possible_moves[a][1].slice()

            if (pieces[a][0] === 0 && pawn_spacial_capture === true) {

                let additional_fields;
                
                if ( ((action === 1 && a >= 16) || (action === 2 && a < 16))) {

                    additional_fields = [[pieces[a][2][0]+1, pieces[a][2][1]-1],[pieces[a][2][0]-1, pieces[a][2][1]-1]]

                }
                else {
                    additional_fields = [[pieces[a][2][0]+1, pieces[a][2][1]+1],[pieces[a][2][0]-1, pieces[a][2][1]+1]]
                }
                //console.log( all_possible_moves[a] )

                /*console.log(pieces[a][2])
                console.log(additional_fields)*/

                all_possible_moves[a][1].push(...additional_fields)

                /*console.log(all_possible_moves[a][1])
                console.log(p_b)*/
            }   
            /* END FOR PAWN DIAGONAL CAPTURE */

            for (let b = 0; b < all_possible_moves[a].length; b++) {//3
                
                for (let c = 0; c < all_possible_moves[a][b].length; c++) {//Single Field

                    if ( (param === "a" && ((action === 1 && a >= 16) || (action === 2 && a < 16))) ||
                    (param === "b" && ((action === 1 && a < 16) || (action === 2 && a >= 16)))) {

                        if (arrayEqual(position, all_possible_moves[a][b][c]) ) {

                            //console.log( all_possible_moves[a][b][c] )

                            if (pieces[a][0] === 0 && b === 0) {
                                continue; //Skip because pawn cannot capture piece directly in front of it
                            }
                            else if (pieces[a][0] !== 5 && exclude_king === undefined) {
                                return [true, a];
                            }
                            else if (pieces[a][0] !== 5 && exclude_king !== undefined) {
                                /*console.log(pieces[a])
                                console.log(position)
                                console.log( all_possible_moves[a][b] )*/
                                return [true, a];
                            }
                            else if (pieces[a][0] === 5 && exclude_king === undefined) {
                                return [true, a];
                            }

                        }
                        
        
                    }
                
                }//)
            }//)
            all_possible_moves[a][1] = p_b;
        }
    }//)

    return [null, null]
    
}

function getSquaresBetween(square1, square2) {

    if (square1 === null) {
        return [];
    }

    let squares_between = [square1];

    let x = square1[0];
    let y = square1[1];

    while (true) {

        //let x, y;

        if (square1[0] < square2[0]) {
            ++x;
        }
        else if (square1[0] > square2[0]) {
            --x;
        }


        if (square1[1] < square2[1]) {
            ++y;
        }
        else if (square1[1] > square2[1]) {
            --y;
        }


        if ( arrayEqual([x,y], square2) ) {
            break;
        }
        else {
            squares_between.push([x,y])
        }

    }

    return squares_between;
    
}

function displayCheckmate(title) {

    document.getElementsByClassName('board-container-main')[0].classList.add('board-checkmate')
    document.getElementsByClassName('checkmate_overlay')[0].classList.add('shown')
    document.getElementById('checkmate_side').innerHTML = title;
    checkmate_disabled = true;

}
export function wronglyMarkedCheckmate() {

    //BUG?: only changed checkmated status in own browser
    document.getElementsByClassName('board-container-main')[0].classList.remove('board-checkmate')
    document.getElementsByClassName('checkmate_overlay')[0].classList.remove('shown')
    checkmate_disabled = false;

}

export function checkIfPossibleField(index, x, y) {

    let fields_move = all_possible_moves[index][0];

    for (let f = 0; f < fields_move.length; f++) {
        if (arrayEqual(fields_move[f], [x,y])) {
            return 0;
        }
    }

    let fields_capture = all_possible_moves[index][1];

    for (let f = 0; f < fields_capture.length; f++) {
        if (arrayEqual(fields_capture[f], [x,y])) {
            return 1;
        }
    }

    return 2;
}

function noOtherPieceToDraw(action, param) {

    for (let i = 0; i < all_possible_moves.length; i++) {

        if (all_possible_moves[i] !== undefined) {

            if ( param === "b" && ((action === 1 && i < 16) || (action === 2 && i >= 16)) ) {
                if (pieces[i][0] !== 5 && (all_possible_moves[i][0].length !== 0 || all_possible_moves[i][1].length !== 0)) {
                    return false //can still move
                }
    
            }
    
            if ( param === "a" && ((action === 1 && i >= 16) || (action === 2 && i < 16)) ) {
                if (pieces[i][0] !== 5 && (all_possible_moves[i][0].length !== 0 || all_possible_moves[i][2].length !== 0)) {
                    return false
                }
                
            }

        }
        
    }

    return true

}