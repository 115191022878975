import React from 'react'

const ErrorBox = ({error}) => {
    return (
        <div className="error_box">
            An error occured when trying to start a new game. Please try again later.
            <div className="error-message" id="error-box-message">{error}</div>
        </div>
    )
}

export default ErrorBox
