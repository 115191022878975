import React from 'react';
import {movePiece} from  '../js/calculate_possible_moves.js';

const Board = (props) => {
    return (
        <table className="board"><tbody>
            {[...Array(8)].map((e, i) => 
            
                <tr className="row" id={i} key={i}>
                    
                    {[...Array(8)].map((e, si) => {

                        const square_id = si+"_"+i;

                        const square_class = ((i*8)+si+i) % 2 === 0 ? "light" : "dark";

                        return <td className={'square square '+square_class} id={square_id} key={square_id} 
                        onMouseDown={() => tdHandleClick([si, i], props.game, props.action)}></td>
                    })}

                </tr>
                
            )}
        </tbody></table>
    )
}

function tdHandleClick(square_coord, game_id, action) {
    movePiece(square_coord, true, game_id, action);
}

export default Board
