import React from 'react';
import axios from 'axios';

import Board from './import/Board.js';
import JoinGame from './import/JoinGame';
import NewGame from './import/NewGame';
import MainMenu from './import/MainMenu';
import Button from './import/Button';
import BoardContainer from './import/BoardContainer';
import ErrorBox from './import/ErrorBox.js';

import {/*arrayEqual,*/ checkForCheckmate, getAllPossibleMoves} from  './js/calculate_possible_moves.js';
import './css/board.css';

//https://www.schachfeld.de/threads/29439-schachbegriffe-deutsch-und-englisch
//https://www.embarc.de/schachbegriffe-auf-englisch/
//The king is not currently in check.

export default class Main extends React.Component {
    state = {
        pieces: [[]],
        pieces_names: ["pawn","rook","knight","bishop","queen","king"],
        piece_selected: false,
        /*game: "",*/
        last_change: 0,
        reload_active: true,
        err_message: "",
        //interval: 0
    }

    componentDidMount() {

        switch (this.props.action) {
            case 1: //1st player
            case 2: //join game //2nd player

                this.reloadBoard(true);

                /*this.setState({interval:*/ 
                    setInterval(() => {
                    //if (this.state.reload_active && this.state.err_message.length > 0) {
                        this.reloadBoard(false);
                    //}
                }, 1000)/*})*/

                break;

            default: //other
                break;

        }

        /*window.addEventListener("focus", this.onFocus)
        window.addEventListener("blur", this.onBlur)*/
    }

    //error_count = 0;
    reloadBoard = (first_load) => {
        const params = new URLSearchParams([ ['game_id', this.props.match.params.game_id],['last_change', this.state.last_change] ]);

        axios.get(`/api/get_chess_board.php`, {params})
        .then(res => {
            const pieces = res.data;

            //console.log(res)
            //console.log(pieces)

            //if ( !arrayEqual( pieces[0], this.state.pieces[0] ) ) { //If the position of any piece has changed since the last reload
            
            if ( pieces.length !== 0 ) {

                //console.log("a")

                this.setState({ last_change: pieces[5] })

                if (this.props.action === 1) {
                    getAllPossibleMoves(pieces, this.props.action);
                    checkForCheckmate(pieces[0], this.props.action);
                }
                else if (this.props.action === 2) { //Reverse array for join game

                    let new_pieces_list = pieces[0].map((it) => {
                        if (it !== null) {
                            if (it[3] === undefined) {
                                return [it[0], it[1], [7-it[2][0], 7-it[2][1]]]
                            }
                            else {
                                return [it[0], it[1], [7-it[2][0], 7-it[2][1]], it[3]]
                            }
                        } 
                        else {
                            return null
                        }
                    });

                    getAllPossibleMoves( [new_pieces_list, !pieces[1], pieces[2], pieces[3]], this.props.action );
                    checkForCheckmate( new_pieces_list, this.props.action );
                }
            
                this.setState({ pieces: pieces });

                if (first_load) {
                    if (this.props.action === 2) {
                        this.increasePlayerCount(pieces[2]);
                    }
                }
            
            }

        }).catch(() => {
            //console.log("reload board error")
            /*this.error_count++;
            if (this.error_count > 5) {
                this.setState({reload_active: false})
                console.log("stop reload board")
            }*/
        })
    }
    

    startNewGame = () => {
        axios.get(`/api/new_game.php`)
        .then(res => {
            const pieces = res.data;
            //this.setState({ pieces });
            window.location.replace("/play/"+pieces[2]);
        }).catch((error) => {
            document.getElementsByClassName("main-menu")[0].classList.add("start-game-error")
            //this.setState({err_message: res})
            if (error.response) {
                //console.log(error.response.data);
                //console.log(error.response.status);
                //console.log(error.response.headers);
                switch (error.response.status) {
                    case 500:
                        this.setState({err_message: "Error 500: Internal Server Error"});
                        break;
                    case 404:
                        this.setState({err_message: "Error 404: File Not Found"});
                        break;
                    default:
                        this.setState({err_message: "Error "+ error.response.status});
                }
            }
        })
    }

    increasePlayerCount(game_id) {
        var params = {
            game_id: game_id
        }
        axios.post('/api/increase_player_count.php', params)/*.then(function(response) {
            console.log(response);
        });*/
    }

    /*onBlur = () => {
        this.setState({reload_active: false})
        clearInterval(this.state.interval)
    }

    onFocus = () => {
        this.setState({reload_active: true})
        this.reloadBoard(false);

        this.setState({interval: setInterval(() => {
            this.reloadBoard(false);
        }, 1000)})
    }*/


    render() {

        switch (this.props.action) {
            case 1: //New game
                return (
                <BoardContainer pieces={this.state.pieces} action={this.props.action} reloading={this.state.reload_active}>
                    <Board game={this.state.pieces} action={this.props.action}/>
                    <NewGame pieces={this.state.pieces} pieces_names={this.state.pieces_names} action={this.props.action}/>
                </BoardContainer>)
            case 2: //Join game
                return (
                <BoardContainer pieces={[this.state.pieces[0], !this.state.pieces[1], this.state.pieces[2], this.state.pieces[3], this.state.pieces[4]]} action={this.props.action} reloading={this.state.reload_active}>
                    <Board game={this.state.pieces} action={this.props.action}/>
                    <JoinGame pieces={this.state.pieces} pieces_names={this.state.pieces_names} action={this.props.action}/> 
                </BoardContainer>)
            default:
                return (<>
                    <MainMenu>
                        <Button onclick={this.startNewGame}>Start new game</Button>
                        <ErrorBox error={this.state.err_message}/>
                    </MainMenu>
                </>)
        }
    }
}



