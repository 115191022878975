import React from 'react';
import { pieceHandleMouseDown, pieceHandleMouseUp } from '../js/piece_click_and_drag';

const NewGame = ({pieces, pieces_names, action}) => {
    
    return pieces[0].map((items, index) => {

        if (items !== null) {

            let color = items[1] === true ? "black" : "white";

            let image_path = "/img/"+items[0]+"_"+color+".svg"; 
    
            let current_piece = pieces_names[items[0]]
    
            let image_alt = (color.charAt(0).toUpperCase() + color.slice(1)) + " " + (current_piece.charAt(0).toUpperCase() + current_piece.slice(1));
    
            let left = items[2][0] * 70;
            let top = items[2][1] * 70;
    
            return (
                <div className="piece" id={"piece_"+index} style={{left: left, top: top}} 
                onMouseUp={()=>pieceHandleMouseUp(items[2], pieces, index, action)} 
                onMouseDown={(e) => pieceHandleMouseDown(items, pieces, index, action, e)} key={index}>
                    <img src={image_path} alt={image_alt} onDragStart={(e) => {e.preventDefault()}}/>
                </div>
            )

        }
        else {
            return "";
        }

    })
}   

export default NewGame
